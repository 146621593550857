// Our main CSS
import '../css/app.css'

document.getElementById('menu-button').addEventListener('click', function () {
  var navMenu = document.getElementById('nav-menu')
  var hamburgerIcon = document.getElementById('hamburger-icon')
  var closeIcon = document.getElementById('close-icon')

  navMenu.classList.toggle('hidden')

  hamburgerIcon.classList.toggle('hidden')
  closeIcon.classList.toggle('hidden')
})

const subLinks = document.querySelectorAll('.sub-link')

if (subLinks.length > 0) {
  subLinks.forEach(function (item) {
    const dropdown = item.querySelector('ul')

    if (dropdown) {
      item.addEventListener('click', function () {
        if (dropdown.style.display === 'block') {
          dropdown.style.display = 'none'
        } else {
          dropdown.style.display = 'block'
        }
      })
    }
  })
}

const logoBannerWrappers = document.querySelectorAll('.LogoBannerWrapper')

if (logoBannerWrappers && logoBannerWrappers.length > 0) {
  import('./Components/index.js')
    .then((module) => {
      logoBannerWrappers.forEach((wrapper) => {
        module.default(wrapper)
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */
